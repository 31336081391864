import Head from "next/head";
import PropTypes from 'prop-types';


const PageHead = ({ title, description, url, keywords, image }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="description"
        content={description || "Varsayılan Sayfa Açıklaması"}
      />
      <meta
        name="keywords"
        content={keywords || "varsayılan, anahtar, kelimeler"}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || "Varsayılan URL"} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description || "Varsayılan Sayfa Açıklaması"}
      />
      <meta property="og:image" content={image || "Varsayılan Resim URL"} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url || "Varsayılan URL"} />
      <meta property="twitter:title" content={title} />
      <meta
        property="twitter:description"
        content={description || "Varsayılan Sayfa Açıklaması"}
      />
      <meta
        property="twitter:image"
        content={image || "Varsayılan Resim URL"}
      />

      {/* Ek Link Etiketleri */}
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {/* <link rel="manifest" href="/manifest.json" /> */}

      {/* Canonical URL */}
      <link rel="canonical" href={url || "Varsayılan URL"} />
    </Head>
  );
};

export default PageHead;

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};